import { fetchUserProfile } from '@/services/apiService';
import { i18n, setI18nLanguage } from '@/i18n';

const state = {
  userProfile: {}
};

const getters = {
  getUserProfileName: state => state.userProfile.first_name,
  getUserLocale: state => state.userProfile.language
};

const actions = {
  fetchProfile({ commit }, payload) {
    commit('SET_USER_PROFILE', {});
    return new Promise((resolve, reject) => {
      fetchUserProfile(payload)
        .then(response => {
          commit('SET_USER_PROFILE', response?.data);
          resolve(response);
        })
        .catch(error => {
          reject(
            new Error(
              `${error?.response?.status}: ${i18n.t(
                'failed_to_fetch_profile_data'
              )}!`
            )
          );
        });
    });
  },

  setUserLocale({ getters, dispatch }, payload) {
    let lang = payload?.lang || getters.getUserLocale;

    if (lang) setI18nLanguage(lang);
    else {
      dispatch('fetchProfile')
        .then(response => {
          lang = response?.data?.language;
          setI18nLanguage(lang);
        })
        .catch(() => {});
      // NOTE: Just to catch the error from fetchProfile,
      // since the error mostly comes on AuthLayout when profile will return 401 (which status code is already handled),
      // I don't think more handling is required as it's handled in other parts of the app(eg: Profile).
    }
  }
};

const mutations = {
  SET_USER_PROFILE(state, payload) {
    state.userProfile = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
