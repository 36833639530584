<template>
  <transition name="slide" mode="out-in">
    <div
      v-if="model.type"
      class="theme-vanderbuild fixed w-full p-6 top-0 right-0 z-100"
    >
      <div
        class="flex max-w-sm mx-auto md:mr-0 tg-desktop-body bg-white shadow-card rounded-md overflow-hidden"
      >
        <div
          v-if="style.icon"
          :class="[style.bg, 'p-4 w-1/5 grid place-items-center text-white']"
        >
          <i :class="['tg-mobile-body far', style.icon]" />
        </div>
        <div
          :class="[
            style.bg,
            style.text,
            'p-4 flex-auto flex flex-row justify-between bg-opacity-12'
          ]"
        >
          <span>
            <b>{{ model.messageBold }}</b> {{ model.message }}
          </span>
          <button
            v-if="model.button"
            type="button"
            :class="[
              style.bg,
              'ml-2 rounded-md text-white px-2 shadow-sm bg-opacity-100 hover:bg-opacity-84'
            ]"
            @click="clickAction(model.button.action)"
          >
            <!-- show({}) ^ dismisses the snackbar upon action button click! -->
            {{ model.button.text }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Snackbar',
  computed: {
    ...mapState('snackbar', ['model']),
    style() {
      let styles = {
        success: {
          bg: 'bg-brand-primary',
          text: 'text-brand-primary',
          icon: 'fa-check'
        },
        error: {
          bg: 'bg-notification-danger',
          text: 'text-notification-danger',
          icon: 'fa-times'
        },
        loading: {
          bg: 'bg-gray-cc',
          text: 'bg-gray-cc',
          icon: 'fa-spinner-third animate-spin'
        },
        notification: {
          bg: 'bg-brand-primary',
          text: 'text-brand-primary',
          icon: 'fa-bell-on'
        }
      };

      return styles[this.model.type];
    }
  },
  methods: {
    ...mapActions('snackbar', ['show']),
    clickAction(action) {
      action();
      this.show({});
    }
  }
};
</script>
