<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="showModal"
      class="fixed p-5 w-full h-full inset-0 z-40 grid place-items-center bg-overlay overflow-auto cursor-pointer"
      @click.self="close"
    >
      <slot name="main" role="dialog">
        <div
          class="flex flex-col text-center w-full max-w-lg bg-white rounded-lg shadow-overlay cursor-auto"
        >
          <div
            v-if="icon"
            class="mx-auto h-16 w-16 grid place-items-center bg-brand-third text-white transform -translate-y-1/2 flex-shrink-0 rounded-full shadow-card"
          >
            <i :class="['fa-lg fal', icon]" />
          </div>

          <p class="mb-5 mx-5 tg-mobile-body">
            <slot name="message">
              {{ message }}
            </slot>
          </p>

          <div
            class="mx-auto transform translate-y-1/2 bg-white rounded-full overflow-hidden"
          >
            <button
              type="button"
              class="px-5 py-0.5 block text-white bg-brand-primary hover:bg-opacity-84"
              aria-label="Close modal"
              @click="close"
            >
              <i class="tg-mobile-button-small far fa-times mr-2" />
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    message: {
      type: String
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
