<template>
  <!-- Toggle Button -->
  <label
    :for="context.id"
    :data-type="context.type"
    :class="['cursor-pointer', context.classes.element]"
  >
    <!-- toggle -->
    <div class="relative">
      <!-- input -->
      <input
        v-model="context.model"
        type="checkbox"
        v-bind="context.attributes"
        class="toggle__line absolute rounded-full border-none cursor-pointer"
        @blur="context.blurHandler"
      />
      <!-- line -->
      <div
        class="toggle__line bg-gray-cc border-2 border-gray-cc rounded-full"
      />
      <!-- dot -->
      <div
        class="toggle__dot border-0.5 border-black border-opacity-25 absolute w-7 h-7 my-auto bg-white rounded-full inset-y-0"
      />
    </div>
  </label>
</template>

<script>
export default {
  name: 'BaseInputToggle',
  props: {
    context: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.toggle__dot {
  left: 2px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16),
    0px 3px 1px rgba(0, 0, 0, 0.1);
}
input:checked ~ .toggle__dot {
  transform: translateX(20px);
}
.toggle__line {
  width: 52px;
  height: 32px;
  transition: all 0.3s ease-in-out;
}
input:checked ~ .toggle__line {
  @apply bg-brand-primary;
  @apply border-brand-primary;
}
</style>
