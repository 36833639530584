import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueFormulate from '@braid/vue-formulate';
import { nl } from './lang/nl';
import { en } from './lang/en';
import { fr } from './lang/fr';

import { setClientsLang } from '@/services/axiosClients';

Vue.use(VueI18n);

const locale = process.env.VUE_APP_I18N_LOCALE || 'nl',
  fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages = { nl, fr, en };

export const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: fallbackLocale,
  messages: messages
});

export function setI18nLanguage(lang) {
  i18n.locale = lang;
  VueFormulate.setLocale(lang);

  setClientsLang(lang);

  document.querySelector('html').setAttribute('lang', lang);
}
