import { authenticate } from '@/services/apiService';
import { i18n } from '@/i18n';

const state = {
  prevRoute: null,
  access: localStorage.getItem('access'),
  refresh: localStorage.getItem('refresh'),
  user: {}
};

const getters = {
  isLoggedIn: state => !!state.access
};

const actions = {
  login({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      authenticate(payload)
        .then(response => {
          if (response.status === 200) {
            let { access, refresh } = response.data;
            dispatch('setJWT', { access, refresh });
          } else if (response.status === 204) {
            throw new Error(i18n.t('user_pass_incorrect'));
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setJWT({ commit }, { access, refresh }) {
    if (access) localStorage.setItem('access', access);
    if (refresh) localStorage.setItem('refresh', refresh);
    commit('SET_STATE_TOKENS', { access, refresh });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      commit('SET_STATE_TOKENS', {});
      resolve();
    });
  }
};

const mutations = {
  SET_PREV_ROUTE(state, payload) {
    state.prevRoute = payload;
  },
  SET_STATE_TOKENS(state, { access, refresh }) {
    state.access = access || '';
    state.refresh = refresh || '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
