/* eslint-disable no-console */
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { i18n } from '@/i18n';
import { getCookie } from '@/helpers';

// Global axios defaults
axios.defaults.timeout = 30_000;
axios.defaults.timeoutErrorMessage = i18n.t('request_timeout');

// httpClient for user related authenticated routes
export const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true // For the cookie based auth, to avoid CORS
});

// bidClient for bid related routes
// Static token, so no interceptor needed
export const bidClient = axios.create({
  baseURL: process.env.VUE_APP_BID_API_URL
});

// calendarClient for calendar service
export const calendarClient = axios.create({
  baseURL: process.env.VUE_APP_CALENDAR_API_URL
});

httpClient.interceptors.request.use(request => {
  const csrftoken = getCookie('csrftoken');
  if (csrftoken) request.headers['X-CSRFToken'] = csrftoken;
  return request;
});

// array of all the clients defined above.
const clients = [httpClient, bidClient, calendarClient];

// Common interceptors
clients.forEach(client => {
  client.interceptors.response.use(
    res => res,
    err => {
      const status = err?.response?.status,
        code = err?.response?.data?.code,
        detail = err?.response?.data?.detail;

      switch (status) {
        case 404:
          // router.replace is used instead of router.push
          // to avoid the user from being stuck in a loop to 404 page when they click the back button.
          router.replace({
            name: 'Error',
            params: { status, message: i18n.t('page_not_found') }
          });
          break;
        case 401:
          console.log(i18n.t('user_not_authenticated'));
          break;
        case 403:
          console.log(i18n.t('user_not_authorized'));
          break;
        case 500:
          store.dispatch('snackbar/show', {
            type: 'error',
            messageBold: status,
            message: i18n.t('internal_server_error')
          });
          break;
        default:
          store.dispatch('snackbar/show', {
            type: 'error',
            messageBold: status,
            message: code ? i18n.t(code) : detail || err?.message
          });
          break;
      }

      throw err;
    }
  );
});

// common exported functions
export function setClientsLang(lang) {
  clients.forEach(client => {
    client.defaults.headers.common['Accept-Language'] = lang;
  });
}

export function JWTRefresh() {
  return httpClient.post(
    '/api/token/refresh',
    {
      refresh: localStorage.getItem('refresh')
    },
    { skipAuthRefresh: true }
  );
}

// Function that will be called to refresh JWT authorization
const refreshJWTLogic = () => {
  const access = localStorage.getItem('access');
  if (access?.length) {
    return JWTRefresh()
      .then(response => {
        const access = response.data.access;
        localStorage.setItem('access', access);
        return Promise.resolve();
      })
      .catch(() => {
        store.dispatch('auth/logout');
        store.dispatch('snackbar/show', {
          type: 'error',
          message: i18n.t('session_expired')
          // The redirect to login is handled by the router hooks.
        });
      });
  } else return Promise.reject(); // We always need to return promise in the refresh function(axios-auth-refresh)
};

// We use interceptor so as to always fetch the latest token
calendarClient.interceptors.request.use(request => {
  const access = localStorage.getItem('access');
  if (access) request.headers['Authorization'] = `Bearer ${access}`;
  return request;
});

// Instantiate the interceptor (you can chain it as it returns the calendarClient instance)
createAuthRefreshInterceptor(calendarClient, refreshJWTLogic, {
  statusCodes: [401, 403]
});
