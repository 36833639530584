/* eslint-disable no-console */

import { register } from 'register-service-worker';
import store from '@/store';
import { i18n } from '@/i18n';

const notifyUserAboutUpdate = worker => {
  store.dispatch('snackbar/show', {
    type: 'notification',
    messageBold: i18n.t('new_version'),
    message: i18n.t('is_available'),
    button: {
      text: i18n.t('update'),
      action: () => {
        worker.postMessage({ type: 'SKIP_WAITING' });
        console.log(i18n.t('customer_portal_updated'));
      }
    },
    timeout: 60000
  });
};

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(registration) {
      console.log('New content is available; please refresh.');
      notifyUserAboutUpdate(registration.waiting);
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });

  let refreshing;
  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing) {
        return;
      }
      window.location.reload();
      refreshing = true;
    });
  }
}
