<template>
  <div
    :class="[
      'place-items-center p-4 text-center',
      { 'border border-dashed border-gray-cc rounded-lg': border },
      textColor
    ]"
  >
    <i :class="['fal text-2xl leading-10', icon]" />
    <br />
    <span class="tg-mobile-body">
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseIconMessage',
  props: {
    icon: {
      type: String
    },
    message: {
      type: String
    },
    border: {
      type: Boolean,
      default: true
    },
    textColor: {
      type: String,
      default: 'text-gray-cc'
    }
  }
};
</script>
