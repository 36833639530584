import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import { en, nl, fr } from '@braid/vue-formulate-i18n';
import { i18n } from '@/i18n';

// ErrorHandler
const apiErrorHandler = function(error) {
  const status = error?.response?.status,
    code = error?.response?.data?.code,
    detail = error?.response?.data?.detail,
    genericErr = `${status ? status + ':' : ''} ${i18n.t(
      'something_went_wrong'
    )}`;

  const errorData = {
      inputErrors: {},
      formErrors: []
    },
    finalError =
      status === 500
        ? i18n.t('internal_server_error')
        : code
        ? i18n.t(code)
        : detail || genericErr;

  errorData.formErrors.push(finalError);

  return errorData;
};

const options = {
  plugins: [en, nl, fr],
  errorHandler: apiErrorHandler,

  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  rules: {
    phone: ({ value }) =>
      !!value.match(
        /^\s*(?:\+?(\d{1,3}))?[ (]*(\d{3,4})[-. )]*(\d{3,4})[-. ]*(\d{3,4})\s*$/
      )
  },

  locale: '',
  locales: {
    en: {
      phone({ name }) {
        return `Please enter a valid ${name}`;
      }
    },
    nl: {
      phone({ name }) {
        return `Voer een geldig ${name}`;
      }
    },
    fr: {
      phone({ name }) {
        return `S’il vous plaît entrer un valide ${name}`;
      }
    }
  },

  library: {
    autocomplete: {
      classification: 'text',
      component: 'BaseInputAutoCompleteCity'
    },
    dial_code_tel: {
      classification: 'text',
      component: 'BaseInputDialCodeTel'
    },
    password: {
      classification: 'text',
      component: 'BaseInputPassword',
      slotProps: {
        component: ['showPasswordButton']
      }
    },
    toggle: {
      classification: 'box',
      component: 'BaseInputToggle'
    },
    timepicker: {
      classification: 'select',
      component: 'BaseInputTimePicker',
      slotProps: {
        component: ['hours', 'minutes']
      }
    }
  },

  slotComponents: {
    label: 'BaseInputLabel'
  },

  slotProps: {
    label: ['labelIcon', 'showRequiredLabel']
  },

  classes: {
    outer({ type }) {
      switch (type) {
        case 'toggle':
          return 'my-6';
        default:
          return 'my-4';
      }
    },
    wrapper({ type }) {
      const styles = {
        toggle: 'mb-1 flex flex-row items-center space-x-5'
      };

      return styles[type];
    },
    input({ classification }) {
      const commonStyles = 'rounded-md h-10 outline-none ',
        baseInputStyles =
          'border border-gray-cc focus:border-tg-light-text border-box w-full ';
      const styles = {
        text: commonStyles + baseInputStyles + 'px-3',
        textarea: commonStyles + baseInputStyles + 'p-3 h-40',
        select: commonStyles + baseInputStyles + 'pl-2.5 pr-5',
        button:
          commonStyles +
          'px-6 text-white tg-mobile-button md:tg-desktop-button bg-brand-primary hover:bg-opacity-84 active:bg-opacity-100 shadow-lg'
      };

      return styles[classification];
    },
    label: 'tg-mobile-label md:tg-desktop-label text-tg-light-text',
    help: 'text-xs mb-1 text-tg-light-text leading-normal',
    error: 'text-error whitespace-pre-wrap' // Whitespace in the input shows as a single whitespace if we don't use whitespace-pre
  }
};

Vue.use(VueFormulate, options);
