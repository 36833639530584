import { bidClient } from './axiosClients';

// Property Bid API
export function createPropertyBid(payload) {
  return bidClient.post('/api/bids', payload);
}
export function addPropertyBuyers(bidId, payload) {
  return bidClient.post(`/api/bids/${bidId}/buyers`, payload);
}
export function finalizePropertyBid(bidId, payload) {
  return bidClient.post(`/api/bids/${bidId}/finalize`, payload);
}
export function checkPropertyBidStatus(bidId) {
  return bidClient.get(`/api/bids/${bidId}`);
}
