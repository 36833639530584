export const propertyRoutes = [
  {
    path: '/:locale/property/:propertyId/',
    component: () =>
      import(
        /* webpackChunkName: "PropertyLayout" */ '@/layouts/PropertyLayout'
      ),
    children: [
      {
        path: 'detail',
        name: 'PropertyDetail',
        component: () =>
          import(
            /* webpackChunkName: "PropertyDetail" */ '@/views/PropertyDetail'
          )
      },
      {
        path: 'visit',
        component: () =>
          import(
            /* webpackChunkName: "PropertyVisitLayout" */ '@/layouts/PropertyVisitLayout'
          ),
        children: [
          {
            path: '',
            name: 'PropertyVisitTimeSlots',
            component: () =>
              import(
                /* webpackChunkName: "PropertyVisitTimeSlots" */ '@/views/PropertyVisitTimeSlots'
              )
          },
          {
            path: 'get-in-touch',
            name: 'PropertyVisitGetInTouch',
            component: () =>
              import(
                /* webpackChunkName: "PropertyVisitGetInTouch" */ '@/views/PropertyVisitGetInTouch'
              )
          },
          {
            path: ':slotId/enter-details',
            name: 'PropertyVisitEnterDetails',
            component: () =>
              import(
                /* webpackChunkName: "PropertyVisitEnterDetails" */ '@/views/PropertyVisitEnterDetails'
              )
          },
          {
            path: 'summary',
            name: 'PropertyVisitSummary',
            component: () =>
              import(
                /* webpackChunkName: "PropertyVisitSummary" */ '@/views/PropertyVisitSummary'
              ),
            props: true
          },
          {
            path: '*',
            redirect: { name: 'PropertyVisitTimeSlots' }
          }
        ]
      },
      {
        path: 'bid',
        name: 'PropertyBid',
        component: () =>
          import(/* webpackChunkName: "PropertyBid" */ '@/views/PropertyBid')
      },
      {
        path: '', // This is added to route from /property/:propertyId/ to /property/:propertyId/detail
        redirect: { name: 'PropertyDetail' }
      },
      {
        path: '*', // For catching all non-existent routes
        redirect: { name: 'PropertyDetail' }
      }
    ]
  }
];
