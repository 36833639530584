import { httpClient } from './axiosClients';

// Auth
export function authenticate(payload) {
  return httpClient.post('/api/authenticate', payload);
}
export function requestJWT(payload) {
  return httpClient.post('/api/authenticate-property-owner', payload);
}
export function ping() {
  return httpClient.get('/api/ping');
}
export function requestPasswordReset(payload) {
  return httpClient.post('/api/portal/password/reset/request', payload);
}
export function resetPassword(payload) {
  return httpClient.post('/api/portal/password/reset', payload);
}
export function passwordChange(payload) {
  return httpClient.post('/api/portal/password/change', payload);
}

// Profile
export function fetchUserProfile() {
  return httpClient.get('/api/portal/profile');
}
export function updateUserProfile(payload) {
  return httpClient.post('/api/portal/profile', payload);
}

// Translations
export function fetchTranslations(language) {
  return httpClient.get('/api/portal/translations', { params: { language } });
}

// City Search API
export function fetchCities({ params }) {
  return httpClient.get('/api/city', { params });
}

// Property API
export function fetchPropertyDetail(propertyId) {
  return httpClient.get(`/api/customer-portal/properties/${propertyId}`);
}
