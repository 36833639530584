import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { ping } from '@/services/apiService';

import { authRoutes } from './modules/authRoutes';
import { dashboardRoutes } from './modules/dashboardRoutes';
import { propertyRoutes } from './modules/propertyRoutes';
import { i18n } from '@/i18n';

Vue.use(VueRouter);

const routes = [
  ...dashboardRoutes,
  ...propertyRoutes,
  ...authRoutes,
  {
    path: '/error/:status',
    name: 'Error',
    component: () => import(/* webpackChunkName: "Error" */ '@/views/Error'),
    props: true
  },
  {
    path: '/*',
    redirect: {
      name: 'Error',
      params: { status: 404, message: i18n.t('page_not_found') }
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === 'Error') return next();

  if (!to.meta?.authRoute) store.commit('auth/SET_PREV_ROUTE', to);

  let locale = null;

  ping()
    .then(response => {
      if (response.status === 200) {
        if (to.meta?.authRoute) {
          store.dispatch('snackbar/show', {
            type: 'success',
            message: i18n.t('already_authenticated')
          });
          next({ name: 'Dashboard' });
        } else next();
      } else {
        throw new Error(response);
      }
    })
    .catch(() => {
      if (!to.meta?.requiresAuth) {
        return next();
      } else {
        next({ name: 'AuthLogin' });
        // Also handles the redirect when the JWT refresh token gets expired.
      }
      store.dispatch('auth/logout');
    });

  if (['nl', 'fr', 'en'].includes(to.params.locale)) {
    locale = { lang: to.params.locale };
  }

  if (!to.meta?.authRoute) store.dispatch('profile/setUserLocale', locale);
});

export default router;
