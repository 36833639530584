<template>
  <div id="app" class="h-full text-tg-light-text font-sans">
    <transition name="fade" mode="out-in">
      <component :is="$route.meta.layout || 'div'">
        <router-view />
      </component>
    </transition>
    <Snackbar />
  </div>
</template>

<script>
import Snackbar from '@/components/Snackbar.vue';

export default {
  name: 'App',
  components: { Snackbar }
};
</script>
