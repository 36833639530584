export const nl = {
  email_label: 'Emailadres',
  password_label: 'Wachtwoord',
  required: 'verplicht',
  password_strength_validation:
    'Wachtwoord moet minstens één numeriek karakter, één speciaal karakter, één hoofdletter en één gewoon teken bevatten.',
  login: 'Inloggen',
  request_reset_link: 'Resetlink versturen',
  request_reset_link_copy:
    'Vul hieronder je e-mailadres in en we sturen meteen een link om je wachtwoord opnieuw in te stellen.',
  reset_link_sent_copy:
    'We hebben een reset link gestuurd naar het e-mailadres dat je hebt ingegeven. Gebuik de link in de email om je wachtwoord opnieuw in te stellen.',
  reset_link_send_again: 'Resetlink nogmaals versturen',
  new_password: 'Je nieuw wachtwoord',
  confirm_new_password: 'Herhaal je nieuw wachtwoord',
  set_password: 'Wachtwoord instellen',
  reset_password_success_copy: 'Je wachtwoord is succesvol gewijzigd!',
  return_to_login: 'Keer terug naar aanmelden',
  personal_information: 'Persoonlijke gegevens',
  first_name: 'Voornaam',
  last_name: 'Achternaam',
  street: 'Straat',
  number: 'Huisnummer',
  box: 'Bus nr',
  city: 'Plaats',
  phone: 'Telefoonnummer',
  mobile: 'GSM nummer',
  birth_date: 'Geboortedatum',
  preferred_language: 'Voorkeurstaal',
  reset_your_password: 'Je paswoord opnieuw instellen',
  by_using_our_customer_portal:
    'Door gebruik te maken van ons klantenportaal ga je akkoord met de',
  terms_of_use: 'gebruikersvoorwaarden',
  and_the: 'en het',
  privacy_policy: 'privacybeleid',
  save: 'Opslaan',
  no_buildings_yet: 'Nog geen panden',
  create_new_search: 'Nieuwe zoekopdracht aanmaken',
  set_preferences_for_newsletters: 'Voorkeuren voor nieuwsbrieven instellen',
  adjust_your_data: 'Je gegevens aanpassen',
  houses: 'Woningen',
  my_profile: 'Mijn profiel',
  my_newsletters: 'Mijn nieuwsbrieven',
  my_searches: 'Mijn zoekopdrachten',
  make_an_offer: 'Een bod doen',
  how_much_do_you_want_to_offer: 'Hoeveel wil je bieden?',
  your_offer: 'Je bod',
  service_fee: 'Servicekosten',
  total_cost: 'Totale kostprijs',
  need_help: 'Hulp nodig?',
  what_is_subject_of_message: 'Wat is het onderwerp van je bericht?',
  send_message: 'Bericht versturen',
  i_wish_to_make_an_offer_with: 'Ik wens een bod te doen met',
  conditions_financing: 'opschortende voorwaarden van financiering',
  who_is_the_buyer: 'Wie is de koper?',
  add_buyer: 'Koper toevoegen',
  buy_with_company: 'Ik koop aan via een vennootschap',
  submit_offer: 'Bod indienen',
  i_agree_with_the: 'Ik ga akkoord met de',
  purchase_conditions: 'koopvoorwaarden',
  logout_success: 'Afmelden succesvol',
  success: 'Succes',
  you_have_been_logged_out: 'Je bent afgemeld',
  details_submitted_successfully: 'Details zijn succesvol verstuurd',
  form_submitted: 'Formulier verstuurd',
  login_success: 'Aanmelden succesvol',
  failed_to_fetch_profile_data: 'Profiel gegevens ophalen is mislukt',
  failed_to_fetch_property_data: 'Pandgegevens ophalen is mislukt',
  something_went_wrong: 'Er is iets mis gegaan, probeer aub opnieuw',
  user: 'Gebruiker',
  welcome: 'Welkom',
  this_field: 'Dit veld',
  required_fields_missing: 'Verplichte velden ontbreken',
  our_advice_page_link: 'https://dewaele.com/een-bod-uitvoeren',
  privacy_policy_property_link:
    'https://www.dewaelevastgoedgroep.be/privacybeleid',
  loading_cities: 'Steden laden',
  no_cities_found: 'Sorry, geen steden gevonden.',
  dashboard_properties_text:
    'Hier kan je een overzicht terugvinden van alle eigendommen die je in beheer hebt bij Dewaele. In dit overzicht worden ook panden getoond die voldoen aan je zoekcriteria',
  suspensive_conditions_of_financing:
    'Je opschortende voorwaarden van financiering',
  amount_for_which_funding:
    'Bedrag waarvoor een financiering wordt aangevraagd',
  period_within_credit_application:
    'Termijn waarbinnen jouw kredietaanvraag goedgekeurd is',
  instellen: 'Instellen',
  modal_service_cost_message:
    'Dit bedrag wordt bovenop jouw bod geteld om de registratierechten (verkooprechten) te berekenen.',
  our_advice_page: 'onze adviespagina',
  about_registration_fees: 'over registratierechten.',
  fieldset_legend_buyer: 'Let op: koop je aan',
  together: 'samen',
  buyer_company_details_message:
    'met je vennootschap, dan zijn er 2 kopers en moet je zowel jezelf als de vennootschap (met de bijhorende vertegenwoordiger(s)) apart als koper toevoegen.',
  you_need_to_accept_this: 'Je moet dit accepteren.',
  legally_authorised_to_represent:
    'Ik mag deze vennootschap rechtsgeldig vertegenwoordigen',
  name_of_company: 'Naam van de vennootschap',
  corporate_form: 'Vennootschapsvorm',
  select_company_type: 'Selecteer het bedrijfstype',
  ondernemingsnummer: 'Ondernemingsnummer',
  company_is_subject_to_vat: 'Deze vennootschap is BTW-plichtig',
  advisor: 'Adviseur',
  nummer: 'Nummer',
  toevoeging: 'Toevoeging',
  placeholder_autocomplete: 'Zoek op postcode of plaatsnaam',
  fieldset_legend_company: 'Vertegenwoordigt door:',
  mobile_number_use_message:
    'Je gsm nummer wordt straks gebruikt voor het tekenen van de aankoopbelofte via sms',
  national_insurance_number: 'Rijksregisternummer',
  birthplace: 'Geboorteplaats',
  mail_me: 'Email me',
  show_less: 'Laat minder zien',
  show_more: 'Toon meer',
  error_translations: 'Ophalen van vertalingen is mislukt!',
  days: 'dagen',
  new_version: 'Nieuwe versie',
  is_available: 'is beschikbaar',
  customer_portal_updated: 'Het klantenportaal is bijgewerkt!',
  update: 'Update',
  bid_message_pending:
    'De verkoopsdocumenten worden op dit moment klaargemaakt. Nog even geduld...',
  bid_message_success:
    'Iedereen die mee aankoopt heeft nu in zijn mailbox een aankoopbelofte ontvangen. Éénmaal iedereen dit heeft ondertekend, is je bod geplaatst en wordt dit voorgelegd aan de verkoper.',
  validation_required_terms: 'U moet de algemene voorwaarden accepteren.',
  validation_required_buyers:
    'U heeft geen kopers toegevoegd, voeg een of meer kopers toe.',
  please_accept_terms: 'Accepteer de voorwaarden om in te dienen.',
  error_bid_not_placed: 'kon het bod niet plaatsen.',
  no_description_available: 'Geen beschrijving beschikbaar.',
  construction_year: 'Bouwjaar',
  buildings: 'Bebouwing',
  ground_surface: 'Grond oppervlakte',
  bedrooms: 'Slaapkamers',
  bathrooms: 'Badkamers',
  garden: 'Tuin',
  terrace: 'Terras',
  living_area: 'Woonoppervlakte',
  your_visit_moment: 'Je bezoekmoment',
  visit_enter_details_message:
    'Het gekozen bezoekmoment is nog steeds vrij. We hebben enkel nog een aantal gegevens nodig en we laten de verkoper weten dat je komt.',
  confirm_appointment: 'Afspraak bevestigen',
  suitable_time_not_found: 'Geen passend tijdstip gevonden?',
  make_personal_appointment:
    'Maak een persoonlijke afspraak met de verkoper via het onderstaande formulier.',
  what_is_suitable_time: 'Welk tijdstip past voor jou?',
  send_proposal: 'Voorstel versturen',
  message_received: 'we hebben je bericht ontvangen!',
  book_another_visit: 'Boek nog een bezoek',
  visit_booked: 'Bezoekmoment bevestigd!',
  visit_booking_confirmed:
    'Goed nieuws! Het bezoekmoment is bevestigd. Je ontvangt alle details via email en hieronder vind je een korte samenvatting.',
  add_to_your_own_calendar: 'Voeg toe aan je eigen agenda',
  back_to: 'Terug naar',
  success_details_submit: 'Details zijn succesvol ingediend.',
  user_pass_incorrect: 'Gebruikersnaam / wachtwoord is onjuist',
  close: 'Sluiten',
  page_not_found: 'Pagina niet gevonden',
  choose_visit_time: 'Kies bezoekmoment',
  no_timeslots_available: 'Geen tijdvakken beschikbaar',
  please_select_circled_dates: 'selecteer een van de omcirkelde datums',
  bid_finance_min_value:
    'De waarde van het bod moet groter zijn dan 1 euro voor financiering.',
  bid_offer_validity: 'Je bod is 10 dagen geldig',
  service_costs_are: 'De servicekosten bedragen',
  modal_total_cost_message:
    'Het bod dat je uitbrengt, is steeds exclusief verkoopbelastingen. In het geval dat je het pand uiteindelijk aankoopt, zal je afhankelijk van welk stelsel er van toepassing is, bovenop je bod en veilingfee ofwel verkooprechten (registratierechten), ofwel BTW moeten betalen.',
  day_0: 'Zondag',
  day_1: 'Maandag',
  day_2: 'Dinsdag',
  day_3: 'Woensdag',
  day_4: 'Donderdag',
  day_5: 'Vrijdag',
  day_6: 'Zaterdag',
  buyer_locale_help_text:
    'Dit is de taal die wordt gebruikt om met de koper te communiceren.',
  message_five_min_difference:
    'Begin- en eindtijd moeten een verschil van ten minste 5 minuten hebben.',
  form_not_valid: 'Gelieve de verplichte velden correct in te vullen.',
  valid_time: 'Selecteer een toekomstige tijd.',
  placeholder_select_interval: 'Selecteer interval',
  label_interval: 'Verdeel in reservaties van',
  option_no_interval: 'Geen interval',
  label_datetime_end: 'Einduur',
  label_datetime_start: 'Startuur',
  add_reservation_block: 'Reservatieblok toevoegen',
  there_are: 'Er worden',
  appointment_blocks: 'afspraakblokken',
  minutes: 'minuten',
  created: 'aangemaakt',
  see_the_slots_to_be_created: 'Bekijk de slots die zullen worden gemaakt',
  new_slot_added_singular: 'Er zijn nieuwe slots toegevoegd',
  new_slot_added_plural: 'Er is een nieuw slot toegevoegd',
  session_expired: 'Uw sessie is verlopen',
  password_show: 'Laat wachtwoord zien',
  password_hide: 'Verberg wachtwoord',
  company_that_wants_to_buy: 'Vennootschap die wil kopen',
  represented_by: 'Vertegenwoordigd door',
  buyer_updated: 'is geupdate',
  buyer_added: 'is toegevoegd als koper',
  visit_reservation_time: 'Reservatie kijkmoment',
  booking: 'Boeking',
  not_yet_reserved: 'Nog niet gereserveerd',
  remove_timeslot: 'Tijdslot verwijderen',
  visit_reserved: 'Kijkmoment gereserveerd',
  has_been_removed: 'is verwijderd',
  property_detail: 'Algemene gegevens',
  property_visit: 'Bezoekregeling',
  could_not_authenticate: 'Kan gebruiker niet verifiëren',
  already_authenticated: 'U bent al ingelogd en u gaat naar Dashboard',
  user_not_authorized: 'Gebruiker is niet geautoriseerd',
  user_not_authenticated: 'De gebruiker is niet geauthenticeerd',
  internal_server_error: 'Interne server fout',
  bid_snackbar_success: 'Het bod is succesvol geplaatst',
  could_not_place_bid: 'Er is iets misgegaan, bod niet geplaatst',
  description: 'Omschrijving',
  please_enter_valid: 'Voer een geldig',
  CANDIDATE_ALREADY_ON_WAITLIST: 'Je staat al op de wachtlijst',
  message_added_to_waitlist: 'Je bent toegevoegd aan de wachtlijst',
  success_conditions_message_bold: 'Opschortende voorwaarden',
  success_conditions_message: 'succesvol ingesteld',
  log_out: 'Uitloggen',
  modal_message_no_buyers: 'Nog geen koper(s) toegevoegd',
  label_company_toggle: 'Ik koop aan met een vennootschap',
  location: 'Locatie',
  select_language: 'Selecteer taal',
  photo: 'Foto',
  property_img: 'Afbeelding van de eigendom',
  max_bookings:
    'Bepaal hoeveel gelijktijdige bezoeken er op dit tijdslot kunnen plaatsvinden',
  from: 'van',
  join_waitlist: 'Word lid van de wachtlijst',
  buyer_mobile_help:
    'Je gsm nummer wordt straks gebruikt voor het tekenen van de aankoopbelofte via sms',
  message: 'Je bericht',
  company_type_options: {
    0: 'bv',
    1: 'nv',
    2: 'cv',
    3: 'vof',
    4: 'commanditaire vennootschap',
    5: 'maatschap',
    6: 'bvba',
    7: 'cvba',
    8: 'commanditaire vennootschap op aandelen',
    9: 'cvoa',
    10: 'e - bvba',
    11: 's - bvba',
    12: 'stille vennootschap',
    13: 'tijdelijke vennootschap',
    14: 'landbouwvennootschap',
    15: 'economisch samenwerkingsverband'
  },
  bid_pre_submit_message:
    'Wanneer je klikt op deze knop ontvang je in je mailbox een link naar de aankoopbelofte, deze kan je rustig doornemen en bij akkoord tekenen.  Controleer bij het niet ontvangen van de mail ook eens je spam.',
  condition_modal_text:
    'Een opschortende voorwaarde van financiering wil zeggen dat je in je compromis een clausule steekt waarin staat dat als de koper geen krediet krijgt bij 3 verschillende kredietinstellingen, hij of zij kan afzien van de verkoop.',
  SLOT_DOES_NOT_EXIST: '',
  SLOT_IS_FULL: '',
  SLOT_DATA_IS_INVALID: '',
  request_timeout: '',
  error_property_details: ''
};
