import { sleep } from '@/helpers.js';
import router from '@/router';

const state = {
  model: {}
};

const getters = {};

const actions = {
  async show({ commit }, model) {
    try {
      commit('updateModel', model);
      if (model.route) await router.push(model.route);
    } catch (error) {
      console.error(error);
      // To catch duplicate navigation failures, and let the snackbar be cleared with the "finally" block, they may not show on the console though.
      // More info: https://github.com/vuejs/vue-router/issues/2872
    } finally {
      await sleep(model.timeout || 2000);
      commit('updateModel', {});
    }
  }
};

const mutations = {
  updateModel(state, payload) {
    state.model = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
