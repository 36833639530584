var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide","mode":"out-in"}},[(_vm.model.type)?_c('div',{staticClass:"theme-vanderbuild fixed w-full p-6 top-0 right-0 z-100"},[_c('div',{staticClass:"flex max-w-sm mx-auto md:mr-0 tg-desktop-body bg-white shadow-card rounded-md overflow-hidden"},[(_vm.style.icon)?_c('div',{class:[_vm.style.bg, 'p-4 w-1/5 grid place-items-center text-white']},[_c('i',{class:['tg-mobile-body far', _vm.style.icon]})]):_vm._e(),_c('div',{class:[
          _vm.style.bg,
          _vm.style.text,
          'p-4 flex-auto flex flex-row justify-between bg-opacity-12'
        ]},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.model.messageBold))]),_vm._v(" "+_vm._s(_vm.model.message)+" ")]),(_vm.model.button)?_c('button',{class:[
            _vm.style.bg,
            'ml-2 rounded-md text-white px-2 shadow-sm bg-opacity-100 hover:bg-opacity-84'
          ],attrs:{"type":"button"},on:{"click":function($event){return _vm.clickAction(_vm.model.button.action)}}},[_vm._v(" "+_vm._s(_vm.model.button.text)+" ")]):_vm._e()])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }