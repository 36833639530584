<template>
  <div
    :class="
      `relative formulate-input-element formulate-input-element--${context.type}`
    "
    :data-type="context.type"
  >
    <input
      v-model="context.model"
      :type="fieldType"
      v-bind="context.attributes"
      :class="[{ 'pr-14': showPasswordButton }, ...context.attributes.class]"
      @blur="context.blurHandler"
    />
    <button
      v-if="showPasswordButton"
      type="button"
      :title="
        fieldType === 'password' ? $t('password_show') : $t('password_hide')
      "
      :aria-label="
        fieldType === 'password' ? $t('password_show') : $t('password_hide')
      "
      class="absolute h-full w-12 right-0 top-0 border-l text-gray-cc hover:text-tg-light-text active:text-gray-cc focus:text-tg-light-text focus:outline-none"
      @click="toggleType"
    >
      <i
        :class="[
          'm-auto fas',
          fieldType === 'password'
            ? 'fa-eye'
            : 'fa-eye-slash text-tg-light-text'
        ]"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseInputPassword',
  props: {
    context: {
      type: Object,
      required: true
    },
    showPasswordButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fieldType: 'password'
    };
  },
  methods: {
    toggleType() {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password';
    }
  }
};
</script>
