<template>
  <div
    :class="
      `formulate-input-element formulate-input-element--${context.type} relative flex`
    "
    :data-type="context.type"
  >
    <select
      v-model="dialCode"
      :class="[computedClasses.width, 'absolute m-2 h-6 text-center']"
      @change="onDialCodeChange"
    >
      <option
        v-for="code in DIAL_CODES"
        :key="code.country"
        :value="code.code"
        :disabled="code.disabled"
      >
        {{ code.code }} {{ code.country }}
      </option>
    </select>
    <input
      :id="context.attributes.id"
      v-model="number"
      type="tel"
      :name="context.attributes.name"
      :placeholder="context.attributes.placeholder"
      :class="[computedClasses.padding, ...context.attributes.class]"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import DIAL_CODES from '@/dialCodes';
import { DEFAULT_DIAL_CODE, formatPhone, parsePhone } from '@/helpers';

export default {
  name: 'BaseInputDialCodeTel',
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialCode: DEFAULT_DIAL_CODE,
      number: ''
    };
  },
  computed: {
    model: {
      get() {
        return this.context.model;
      },
      set(val) {
        this.context.model = val;
      }
    },
    DIAL_CODES() {
      return DIAL_CODES;
    },
    computedClasses() {
      const dialCodeLength = this.dialCode?.length; // Includes "+"
      // Classes need to be pre-declared and not computed dynamically, for tailwind to work properly.
      switch (dialCodeLength) {
        case 2:
          return { padding: 'pl-[50px]', width: 'w-[37px]' };
        case 3:
          return { padding: 'pl-[60px]', width: 'w-[47px]' };
        case 4:
          return { padding: 'pl-[70px]', width: 'w-[57px]' };
        default:
          return { padding: 'pl-[50px]', width: 'w-[38px]' };
      }
    }
  },
  watch: {
    model: {
      immediate: true,
      handler(value) {
        const { dialCode, number } = parsePhone(value, true);
        this.dialCode = dialCode;
        this.number = number;
      }
    }
  },
  methods: {
    onBlur(event) {
      const number = event.target.value;
      const phoneNumber = formatPhone(this.dialCode, number);

      if (!phoneNumber) this.number = '';
      this.model = phoneNumber;
      this.context.blurHandler(event);
    },
    onDialCodeChange(event) {
      const dialCode = event.target.value;
      const phoneNumber = formatPhone(dialCode, this.number);

      if (!phoneNumber) this.number = '';
      this.model = phoneNumber;
    }
  }
};
</script>
