import { i18n } from './i18n';
import { format } from 'date-fns';
import DIAL_CODES from './dialCodes';

export async function sleep(time) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

// Chunking functions - start
export function all(items, fn) {
  const promises = items.map(item => fn(item));
  return Promise.all(promises);
}

export function series(items, fn) {
  let result = [];
  return items
    .reduce((acc, item) => {
      acc = acc.then(() => {
        return fn(item).then(res => result.push(res));
      });
      return acc;
    }, Promise.resolve())
    .then(() => result);
}

export function splitToChunks(items, chunkSize = 50) {
  const result = [];
  for (let i = 0; i < items.length; i += chunkSize) {
    result.push(items.slice(i, i + chunkSize));
  }
  return result;
}

export function chunks(items, fn, chunkSize = 50) {
  let result = [];
  const chunks = splitToChunks(items, chunkSize);
  return series(chunks, chunk => {
    return all(chunk, fn).then(res => (result = result.concat(res)));
  }).then(() => result);
}
// Chunking functions - end

export function currency(amount) {
  if (!amount) return '-';
  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency: 'EUR'
  }).format(amount);
}

export function addDaysText(numberOfDays) {
  return numberOfDays ? numberOfDays + ' ' + i18n.t('days') : '-';
}

export function formatHHmm(datetime) {
  return format(new Date(datetime), 'HH:mm');
}

export function formatDateForLocale(date) {
  let options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  };

  return new Date(date).toLocaleDateString(i18n.locale, options);
}

let todayMilliseconds = new Date().getTime();

function toCalendarDate(milliseconds) {
  return new Date(milliseconds).toISOString().split('T')[0]; // Returns the date in YYYY-MM-DD format
}

export const today = {
  date: toCalendarDate(todayMilliseconds),
  forValidation: toCalendarDate(todayMilliseconds + 86_400_000) // Today + 1 day(to allow today)
};

export const minEighteenYears = {
  date: toCalendarDate(todayMilliseconds - 568_036_800_000), // Today - 18 years in ms
  forValidation: toCalendarDate(
    todayMilliseconds - 568_036_800_000 + 86_400_000 // Today - 18 years in ms + 1 day(to allow the 18th year's date)
  )
};

export function formatPhone(dialCode, number) {
  number = number.replace(/[^\d]/g, ''); // Removes everything that isn't a number
  if (!number) return '';

  number =
    number.charAt(0) === '0'
      ? number.replace(/^0+/, '') // Removes the leading zero
      : number;
  return dialCode + number;
}

export const DEFAULT_DIAL_CODE = '+32';

export function parsePhone(value, asObject) {
  let dialCode = DEFAULT_DIAL_CODE;
  let number = value;
  // When using this helper function in the ContactConnector component
  // 3CX (our telephone system) will send international phone numbers
  // starting with a double zero. For the parsing to work correctly
  // we need to convert this leading zeros into a plus sign
  if (value?.substring(0, 2) === '00') value = `+${value.slice(2)}`;

  if (value?.includes('+')) {
    if (value.slice(0, 3) === DEFAULT_DIAL_CODE) number = value.slice(3);
    else {
      const found = DIAL_CODES.find(el => value.includes(el.code));
      if (found?.code) {
        dialCode = found.code;
        number = value.slice(dialCode.length);
      } else number = value.slice(1); // Remove the plus sign from the number
    }
  }

  return asObject ? { dialCode, number } : dialCode + number;
}

export function blockPeriodInput(event) {
  if (event.key === '.') event.preventDefault();
}

export const langOptions = {
  nl: 'Nederlands',
  en: 'English',
  fr: 'Français'
};

export function getCookie(cookieName) {
  const cookie = {};
  document.cookie.split(';').forEach(function(el) {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
}
