const DashboardLayout = () =>
  import(/* webpackChunkName: "DashboardLayout" */ '@/layouts/DashboardLayout');

export const dashboardRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  },
  {
    path: '/houses',
    name: 'Houses',
    component: () => import(/* webpackChunkName: "House" */ '@/views/Houses'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "Profile" */ '@/views/Profile'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  },
  {
    path: '/newsletters',
    name: 'Newsletters',
    component: () =>
      import(/* webpackChunkName: "Newsletters" */ '@/views/Newsletters'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  },
  {
    path: '/searches',
    name: 'Searches',
    component: () =>
      import(/* webpackChunkName: "Searches" */ '@/views/Searches'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "Help" */ '@/views/Help'),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true
    }
  }
];
