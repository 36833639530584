const AuthLayout = () =>
  import(/* webpackChunkName: "AuthLayout" */ '@/layouts/AuthLayout');

export const authRoutes = [
  {
    path: '/auth/login',
    name: 'AuthLogin',
    component: () =>
      import(/* webpackChunkName: "AuthLogin" */ '@/views/AuthLogin'),
    meta: {
      layout: AuthLayout,
      authRoute: true,
      schema: 'authLogin'
    }
  },
  {
    path: '/auth/reset-password/request-link',
    name: 'AuthResetRequest',
    component: () =>
      import(
        /* webpackChunkName: "AuthResetRequest" */ '@/views/AuthResetRequest'
      ),
    meta: {
      layout: AuthLayout,
      authRoute: true
    }
  },
  {
    path: '/auth/reset-password/request-link/sent',
    name: 'AuthResetLinkSent',
    component: () =>
      import(/* webpackChunkName: "AuthMessages" */ '@/views/AuthMessages'),
    meta: {
      layout: AuthLayout,
      authRoute: true,
      schema: 'authResetLinkSent'
    }
  },
  {
    path: '/auth/reset-password/change-password/:token',
    name: 'AuthResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "AuthResetPassword" */ '@/views/AuthResetPassword'
      ),
    meta: {
      layout: AuthLayout,
      authRoute: true
    }
  },
  {
    path: '/auth/reset-password/success',
    name: 'AuthResetSuccess',
    component: () =>
      import(/* webpackChunkName: "AuthMessages" */ '@/views/AuthMessages'),
    meta: {
      layout: AuthLayout,
      authRoute: true,
      schema: 'authResetSuccess'
    }
  }
];
