<template>
  <div
    :class="
      `formulate-input-element formulate-input-element--${context.type} relative`
    "
    :data-type="context.type"
  >
    <input
      v-model="search"
      type="text"
      v-bind="context.attributes"
      autocomplete="off"
      class="pr-8"
      @input="debouncedLoadCities"
      @keydown.enter.prevent="onEnter"
      @keydown.tab.exact="onEnter"
      @keydown.esc.stop="onEsc"
      @keydown.shift.tab.exact="onEsc"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @blur="context.blurHandler"
    />
    <button
      v-if="context.hasValue"
      type="button"
      :title="$t('clear')"
      class="p-2 absolute right-0 inset-y-0"
      @click.stop="setResult('')"
    >
      <i class="fal fa-times-circle" />
    </button>
    <ul
      v-show="isOpen"
      tabindex="-1"
      class="absolute max-h-48 w-full bg-white rounded-md overflow-y-auto shadow-card z-20"
    >
      <li v-if="isLoading" key="loading" class="p-2">
        {{ $t('loading_cities') }}...
      </li>
      <li
        v-for="(result, index) in results"
        v-else-if="results.length"
        :key="index"
        :class="[
          'p-2 cursor-pointer hover:bg-gray-e9',
          { 'bg-gray-e9': index === arrowCounter }
        ]"
        @mouseenter="arrowCounter = index"
        @click="setResult(result)"
      >
        {{ getCityDisplayValue(result) }}
      </li>
      <li v-else key="error" class="p-2">
        {{ error ? error : $t('no_cities_found') }}
      </li>
    </ul>
  </div>
</template>

<script>
import { fetchCities } from '@/services/apiService';
import debounce from 'lodash/debounce';

export default {
  name: 'BaseInputAutoCompleteCity',
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      results: [],
      error: '',
      search: '',
      isLoading: false,
      arrowCounter: -1
    };
  },
  computed: {
    model: {
      get() {
        return this.context.model;
      },
      set(val) {
        this.context.model = val;
      }
    }
  },
  watch: {
    model(value) {
      this.search = this.getCityDisplayValue(value);
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    debouncedLoadCities: debounce(
      function(event) {
        this.loadCities(event.target.value);
      },
      300,
      { maxWait: 3000 }
    ),

    loadCities(query) {
      this.isLoading = true;
      const trimmedQuery = query.trim();
      const requestData = {
        params: { query: trimmedQuery }
      };

      fetchCities(requestData)
        .then(response => {
          this.results = response.data?.results;
        })
        .catch(error => {
          this.results = [];
          this.error = error.detail;
        })
        .finally(() => {
          this.isLoading = false;
          this.isOpen = true;
          this.arrowCounter = -1;
        });
    },

    setResult(result) {
      this.model = result;
      this.results = [];
      this.isOpen = false;
    },

    getCityDisplayValue(city) {
      return city
        ? city.zip_code + ', ' + city[`name_${this.$i18n.locale}`]
        : '';
    },

    onArrowDown() {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      const result = this.results[this.arrowCounter];
      if (result) this.setResult(result);
    },
    onEsc() {
      if (!this.search?.length) this.setResult('');
      else this.search = this.getCityDisplayValue(this.model);
      this.isOpen = false;
      this.arrowCounter = -1;
    },

    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.onEsc();
      }
    }
  }
};
</script>
