import Vue from 'vue';
import { fetchPropertyDetail } from '@/services/apiService';
import {
  createPropertyBid,
  addPropertyBuyers,
  finalizePropertyBid,
  checkPropertyBidStatus
} from '@/services/bidService';
import { parsePhone } from '@/helpers';
import { i18n } from '@/i18n';

const state = {
  property: {},
  amount: 1,
  serviceCost: 2499,
  propertyConditions: {
    conditions: false,
    conditions_amount: 1,
    conditions_term: 15
  },
  propertyBuyers: [],
  propertyBidStatus: {},
  propertyVisitDate: null
};

const getters = {
  totalCost: state => parseFloat(state.amount) + state.serviceCost
};

const actions = {
  fetchPropertyDetails({ commit }, payload) {
    commit('SET_PROPERTY_DETAIL', {});
    return new Promise((resolve, reject) => {
      fetchPropertyDetail(payload)
        .then(response => {
          commit('SET_PROPERTY_DETAIL', response?.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async submitBid({ state, commit, dispatch }, payload) {
    let bid = {
        ...payload,
        ...state.propertyConditions,
        service_cost: state.serviceCost
      },
      // Need to clone this deeply in order to not affect the main form data in case an error is present and user needs to edit stuff, ex: city data
      buyers = JSON.parse(JSON.stringify(state.propertyBuyers)),
      bidId = null,
      bidStatusResponse = null;

    const response = await createPropertyBid(bid);
    bidId = response.data.id;

    for (let index = 0; index < buyers.length; index++) {
      const buyer = buyers[index];

      buyer.mobile = parsePhone(buyer.mobile);
      if (buyer.phone) buyer.phone = parsePhone(buyer.phone);
      delete buyer.required_toggle;
      buyer.city = buyer.city.id;
      if (buyer.company_city) buyer.company_city = buyer.company_city.id;

      await addPropertyBuyers(bidId, buyer);
    }

    await finalizePropertyBid(bidId);

    bidStatusResponse = await checkPropertyBidStatus(bidId);
    commit('CHANGE_PROPERTY_BID_STATUS', {
      propertyID: bid.property_id,
      status: bidStatusResponse.data.status
    });

    await new Promise((resolve, reject) => {
      const bidStatusTimer = setInterval(() => {
        checkPropertyBidStatus(bidId)
          .then(response => {
            commit('CHANGE_PROPERTY_BID_STATUS', {
              propertyID: bid.property_id,
              status: response.data.status
            });

            if (response.data.status === 4) {
              clearInterval(bidStatusTimer);
              dispatch(
                'snackbar/show',
                {
                  type: 'success',
                  messageBold: '',
                  message: i18n.t('bid_snackbar_success')
                },
                { root: true }
              );
            }
          })
          .catch(error => {
            reject(error);
          });
      }, 5000);

      resolve();
    });
  }
};

const mutations = {
  SET_PROPERTY_DETAIL(state, payload) {
    state.property = payload;
  },
  SET_PROPERTY_CONDITIONS(state, payload) {
    state.propertyConditions = payload;
  },
  SET_PROPERTY_BID_AMOUNT(state, payload) {
    state.amount = payload;
  },
  ADD_PROPERTY_BUYER(state, payload) {
    state.propertyBuyers.push(payload);
  },
  DELETE_PROPERTY_BUYER(state, index) {
    state.propertyBuyers.splice(index, 1);
  },
  EDIT_PROPERTY_BUYER(state, payload) {
    state.propertyBuyers.splice(payload.index, 1, payload.buyer);
  },
  CHANGE_PROPERTY_BID_STATUS(state, { propertyID, status }) {
    Vue.set(state.propertyBidStatus, propertyID, status);
  },
  SET_VISIT_DATE(state, payload) {
    state.propertyVisitDate = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
